angular.module("app")
    .factory("_view", function(_v, $rootScope, $timeout, _apiService, _flashService, _appStorage, _window) {

        // All user data
        var user;

        // Navigation menu model
        var navigation = {
            farms: [],
            standaloneFields: []
        };

        // Raw list of navigation fields
        var fields = [];

        // Raw list of navigation farms
        var farms = [];

        // Map position
        var mapPosition = {};

        // Params
        var accountParam;           // user currentAccount
        var viewParam;              // map/weather switch
        var settingsParam = false;  // settings state

        var farmParam;              // selected farm
        var fieldParam;             // selected field
        var panelFieldParam;        // selected field in panel

        var fromParam;              // calendar start date
        var granularityParam;       // calendar granularity

        // Edit map state
        var editMap = false;

        //edit spray state
        var editSpray = null;

        //view spray state
        var viewSpray = null;

        //view photoNote state
        var viewPhotoNote = null;

        //view thresholds state
        var viewThresholds = null;

        //view cropMonitor state
        var viewCropMonitor = null;

        //add crop monitor state
        var addCropMonitor = false;

        var rebuildNavigation = true;

        var scoutPoint = null;

        var fieldCrops = false;

        var forecastOpened = false;

        //all diseases forecast for current crop
        var forecastAll = null;

        //selected disease forecast for current crop
        var forecastData = null;
        /**
         * App entry point
         */
        function initRouting() {
            _v.onChangeAndTrigger(function(current, prev) {
                //console.log("_v.onChangeAndTrigger rebuildNavigation " + rebuildNavigation);
                //console.log(current);

                viewParam = current.view || "m";
                settingsParam = current.settings == 'true';
                mapPosition.zoom = current.zoom;
                mapPosition.lat = current.lat;
                mapPosition.lng = current.lng;
                granularityParam = current.granularity || 'd';

                // reset all after account switch or restore
                if (current.account != prev.account) {
                    rebuildNavigation = true;
                }

                $timeout(function () {   //for ui refresh
                    accountParam = current.account;  //accountParam always contains parameter value, not changed, readonly

                    //account restore logic
                    if (!accountParam && _appStorage.get('account')) {
                        _v.change({set: {account: _appStorage.get('account')}});
                        return;
                    }

                    var goodDate = _.isString(current.from) && current.from.length > 0 && moment(current.from).isValid();
                    if (!goodDate) {
                        _v.change({set: {from: moment.utc().format("YYYY-MM-DDTHH")}});
                        return;
                    }
                    fromParam = current.from ? moment.utc(current.from) : moment.utc();

                    if (rebuildNavigation) {
                        farmParam = null;
                        fieldParam = null;
                        panelFieldParam = null;
                        editMap = false;
                        viewCropMonitor = null;
                        scoutPoint = null;
                        viewSpray = null;
                        viewPhotoNote = null;
                        farms = [];
                        fields = [];
                        navigation = {
                            farms: [],
                            standaloneFields: []
                        };

                        _apiService.currentUser().then(function(response) {
                            user = response.data;
                            user.accounts = _.sortBy(user.accounts, account => account.name);

                            _appStorage.set('lang', user.language);

                            //check account param
                            if (user.accounts.length > 0) {
                                // Check if current account is real. Otherwise set default account
                                if (user.accounts.some(account => account.id == accountParam)) {
                                    _appStorage.set('account', accountParam);
                                } else {
                                    _v.change({set: {account: user.accounts[0].id}});
                                    return;
                                }
                            } else {
                                if (accountParam) { //no user accounts but accountParam present, remove it
                                    _appStorage.remove('account');
                                    _v.change({set: {account: undefined}});
                                    return;
                                }
                            }

                            if (accountParam) { //accountParam is checked avd valid here
                                _apiService.navigation(accountParam).then(function (response) {
                                    var correct = true;

                                    if (current) {
                                        farmParam = current.farm;
                                        fieldParam = current.field;
                                        panelFieldParam = current.pfield;
                                    }

                                    farms = [];
                                    fields = [];

                                    farms = farms.concat(response.data.farms);
                                    fields = fields.concat(response.data.fields);

                                    buildNavigation();

                                    if (farmParam || fieldParam || panelFieldParam) { //check parameters are legal
                                        if (fieldParam) {
                                            correct = correct && fields.find(field => field.id == fieldParam);
                                        }

                                        if (panelFieldParam) {
                                            correct = correct && fields.find(field => field.id == panelFieldParam);

                                            // fieldParam and panelFieldParam can not be set at the same time
                                            if (fieldParam) {
                                                correct = false;
                                            }

                                            // panelFieldParam and farmParam must be se at the same time
                                            if (!farmParam) {
                                                correct = false;
                                            }
                                        }

                                        if (farmParam) {
                                            correct = correct && farms.find(farm => farm.id == farmParam);
                                        }

                                        // Check if field belongs to farm
                                        if (farmParam && fieldParam && correct) {
                                            correct = (fields.find(field => field.id == fieldParam).farm.id == farmParam);
                                        }

                                        // Check if panel-field belongs to farm
                                        if (farmParam && panelFieldParam && correct) {
                                            correct = (fields.find(field => field.id == panelFieldParam).farm.id == farmParam);
                                        }
                                    }

                                    if (!correct) {
                                        _v.change({set: {farm: undefined, field: undefined, pfield: undefined}});
                                        return;
                                    }

                                    if (!panelFieldParam && !fieldParam) {
                                        if (granularityParam == 's') {
                                            _v.change({set: {granularity: undefined}});
                                            return;
                                        }
                                    }

                                    viewCropMonitor = current.viewCropMonitor;
                                    viewSpray = current.viewSpray;
                                    viewPhotoNote = current.viewPhotoNote;
                                    addCropMonitor  = current.addCropMonitor;
                                    editSpray  = current.editSpray;

                                    rebuildNavigation = false;
                                    $rootScope.$broadcast('_view:urlReady');

                                    // Set viewport height
                                    _window.onResize(refreshMinHeight);
                                    refreshMinHeight();
                                });
                            } else {
                                rebuildNavigation = false;
                                $rootScope.$broadcast('_view:urlReady');

                                // Set viewport height
                                _window.onResize(refreshMinHeight);
                                refreshMinHeight();
                            }
                        });
                    } else {
                        farmParam = current.farm;
                        fieldParam = current.field;
                        panelFieldParam = current.pfield;
                        viewCropMonitor = current.viewCropMonitor;
                        viewSpray = current.viewSpray;
                        viewPhotoNote = current.viewPhotoNote;
                        addCropMonitor  = current.addCropMonitor;
                        editSpray = current.editSpray;

                        if (_flashService.get('panRequiredEvent') && isMap()) {
                            $rootScope.$broadcast('panRequiredEvent');
                        }

                        if (editMap) {
                            return; //ignore all other events
                        }

                        if (current.view != prev.view) {
                            $rootScope.$broadcast('_view:urlReady');
                            return;
                        }

                        if ((current.from != prev.from) || (current.granularity != prev.granularity)) {
                            $rootScope.$broadcast('_view:fromChanged');
                            return;
                        }

                        if (current.field != prev.field || current.farm != prev.farm) {
                            $rootScope.$broadcast('_view:fieldChanged');
                            return;
                        }

                        if (current.pfield != prev.pfield) {
                            $rootScope.$broadcast('_view:panelFieldChanged');
                            return;
                        }

                        if (current.settings != prev.settings) {
                            $rootScope.$broadcast('_view:urlReady');
                            refreshMinHeight();
                            return;
                        }

                        if ((current.viewCropMonitor != prev.viewCropMonitor)) {
                            scoutPoint = null;

                            var tasks = _formEventTasks('viewCropMonitor');
                            $rootScope.$broadcast('_view:viewCropMonitorChanged', tasks);
                        }

                        if (current.viewSpray != prev.viewSpray) {
                            var tasks = _formEventTasks('viewSpray');
                            $rootScope.$broadcast('_view:viewSprayChanged', tasks);
                        }

                        if (current.viewPhotoNote != prev.viewPhotoNote) {
                            $rootScope.$broadcast('_view:viewPhotoNoteChanged');
                        }

                        if (current.addCropMonitor != prev.addCropMonitor) {
                            var tasks = _formEventTasks('addCropMonitor');
                            $rootScope.$broadcast('_view:addCropMonitorChanged', tasks);
                        }

                        if (current.editSpray != prev.editSpray) {
                            var tasks = _formEventTasks('editSpray');
                            $rootScope.$broadcast('_view:editSprayChanged', tasks);
                        }

                        function _formEventTasks(taskType) {
                            return {
                                tasks: {
                                    prev: prev[taskType],
                                    current: current[taskType]
                                }
                            };
                        }
                    }
                });
            });
        }

        function buildNavigation() {
            navigation = {
                farms: [],
                standaloneFields: []
            };

            fields.forEach(field => {
                // Skip deleted fields
                if (field.deleted) {
                    return;
                }
                if (!field.farm) {
                    navigation.standaloneFields.push({name: field.name, id: field.id, timezoneId: field.timezoneId});
                } else {
                    var farm = navigation.farms.find(farm => farm.id == field.farm.id);
                    if (!farm) {
                        var farmX = farms.find(farm => farm.id == field.farm.id);
                        farm = {name: farmX.name, id: farmX.id, fields:[]};
                        navigation.farms.push(farm);
                    }
                    farm.fields.push({name: field.name, id: field.id, farmId: field.farm.id, timezoneId: field.timezoneId})
                }
            });

            // Sort farms and fields
            navigation.farms = _.sortBy(navigation.farms, farm => farm.name);
            navigation.farms.forEach(farm => {
                farm.fields = _.sortBy(farm.fields, field => field.name);
            });
            navigation.standaloneFields = _.sortBy(navigation.standaloneFields, field => field.name);
        }

        function isEditMap() {
            return isMap() && editMap;
        }

        function isEditSpray() {
            return  editSpray != null;
        }

        function isViewSpray() {
            return viewSpray != null;
        }

        function isViewPhotoNote() {
            return viewPhotoNote != null;
        }

        function isViewThresholds() {
            return viewThresholds != null;
        }

        function isMap() {
            return !settingsParam && viewParam == "m";
        }

        function isWeather() {
            return !settingsParam && viewParam == "w";
        }

        function isSettings() {
            return settingsParam;
        }

        function isViewCropMonitor() {
            return viewCropMonitor != null;
        }

        function isAddCropMonitor() {
            return addCropMonitor;
        }

        function toggle() {
            if (settingsParam) {
                settingsParam = false;
                _v.change({set: {"settings": undefined}});
            } else {
                _v.change({set: {"view": (viewParam == "w") ? "m" : "w"}});
            }
        }

        function get() {
            return viewParam;
        }

        function refreshMinHeight() {
            var $element = $(".c-body-container");
            var headerHeight = $('.js-l-header').height() || 0,
                footerHeight = $('.js-f-footer').height() || 0,
                height = headerHeight + footerHeight;

            if (isSettings()) {
                $element.parent().css('height', '100%');
                $element.css('minHeight', 'initial');
            } else {
                $element.parent().css('height', 'calc(100vh - ' + height + 'px)');
                $element.parent().css('minHeight', 468 - height);
                $element.css('minHeight', 468 - height);
            }
        }

        return {
            initRouting: initRouting,
            get: get,
            toggle: toggle,
            is: {
                map: isMap,
                editMap: isEditMap,
                weather: isWeather,
                settings: isSettings,
                editSpray: isEditSpray,
                viewSpray: isViewSpray,
                viewPhotoNote: isViewPhotoNote,
                viewThresholds: isViewThresholds,
                viewCropMonitor: isViewCropMonitor,
                addCropMonitor: isAddCropMonitor,
                forecastOpened: function () { return forecastOpened; }
            },
            getForecastData: function () {
                return forecastData;
            },
            setForecastData(d) {
                forecastData = d;
            },
            getForecastAll() {
                return forecastAll;
            },
            setForecastAll(s) {
                forecastAll = s;
            },
            setForecastOpened: function (x) {
                forecastOpened = x;
                $rootScope.$broadcast('_view:forecastChanged');
            },
            setEditSpray: function(s) {
                editSpray = s;
            },
            setViewThresholds: function(t) {
                viewThresholds = t;
            },
            getViewThresholds() {
                return viewThresholds;
            },
            getViewSpray() {
                return viewSpray;
            },
            getViewPhotoNote() {
                return viewPhotoNote;
            },
            getEditSpray() {
                return editSpray;
            },
            getViewCropMonitor() {
                return viewCropMonitor;
            },
            setScoutPoint(s) {
                scoutPoint = s;
            },
            getScoutPoint() {
                return scoutPoint;
            },
            setEditMap(e) {
                editMap = e;
            },
            setFieldCrops(fc) {
                fieldCrops = fc;
            },
            //must be used only in calendar. all other clients must use calendar normalized from
            getFrom: function() {
                return fromParam;
            },
            getGranularity: function() {
                return granularityParam;
            },
            getNavigation: function() {
                return navigation;
            },
            getFields: function() {
                return fields;
            },
            getFarms: function() {
                return _.filter(farms, function (f) {return _.isUndefined(f.deleted)});
            },
            getFarmById: function(farmId) {
                return farms.find(farm => farm.id == farmId);
            },
            getFieldById: function(fieldId) {
                return fields.find(field => field.id == fieldId);
            },
            addField: function(field) {
                fields.push(field);
                buildNavigation();
            },
            addFarm: function(farm) {
                farms.push(farm);
                buildNavigation();
            },
            getAccounts: function() {
                return user.accounts;
            },
            getCurrentAccount: function() {
                return accountParam;
            },
            getCurrentAccountInfo: function() {
                return user && user.accounts.find(user => user.id == accountParam);
            },
            getCurrentUser: function() {
                return user;
            },
            getAvatarUrl: function(){
                if (user && user.avatarUrl) {
                    return "/avatar/" + user.avatarUrl;
                }

                return "/img/empty_ava.jpg";
            },
            getCurrentField: function() {
                if (rebuildNavigation) {
                    return; // navigation.farms isn't ready yet
                }

                var field;
                if (fieldParam) {
                    if (farmParam) {
                        var farm = navigation.farms.find(farm => farm.id == farmParam);
                        if (farm) {
                            field = farm.fields.find(field => field.id == fieldParam);
                        }
                    } else {
                        field = navigation.standaloneFields.find(field => field.id == fieldParam);
                    }
                }
                return field;
            },
            getCurrentFarm: function() {
                var farm;
                if (farmParam) {
                    farm = navigation.farms.find(farm => farm.id == farmParam);
                }
                return farm;
            },
            getPanelField: function() {
                var field;
                if (farmParam) {
                    var farm = navigation.farms.find(farm => farm.id == farmParam);
                    field = farm.fields.find(field => field.id == panelFieldParam);
                } else {
                    field = navigation.standaloneFields.find(field => field.id == panelFieldParam);
                }

                return field;
            },
            getTimezone: function() {
                var timezone = moment.tz.guess();


                console.log("getTimezone return", timezone);
                return timezone;
            },
            isPanelFieldSelected: function() {
                return panelFieldParam && true;
            },
            isFieldSelected: function() {
                return fieldParam && true && !farmParam;
            },
            isFarmSelected: function() {
                return farmParam && true && !fieldParam;
            },
            isFarmAndFieldSelected: function() {
                return fieldParam && farmParam && true;
            },
            isFieldWithCrops: function() {
                return fieldCrops;
            },
            buildNavigation: function() {
                buildNavigation();
            },
            deleteField: function(deletedField) {
                fields.find(field => field.id == deletedField.id)['deleted'] = true;
                buildNavigation();
            },
            deleteFarm: function(deletedFarmId) {
                _.each(fields, function(field) {
                    if (field.farm && field.farm.id == deletedFarmId) {
                        delete field.farm;
                    }
                });
                farms.find(farm => farm.id == deletedFarmId)['deleted'] = true;
                buildNavigation();
            },
            hasMapPositionParams: function() {
                return !_.isUndefined(mapPosition.zoom && mapPosition.lat && mapPosition.lng);
            },
            getLatLngZoom: function() {
                return mapPosition;
            },
            refreshMinHeight: refreshMinHeight
        };
    })
    .run(function(_view) {
        _view.initRouting();
    });